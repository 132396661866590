import { ReactElement } from 'react';

export function EditIcon(): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 4.99919H6C5.46957 4.99919 4.96086 5.2099 4.58579 5.58497C4.21071 5.96004 4 6.46875 4 6.99919V17.9992C4 18.5296 4.21071 19.0383 4.58579 19.4134C4.96086 19.7885 5.46957 19.9992 6 19.9992H17C17.5304 19.9992 18.0391 19.7885 18.4142 19.4134C18.7893 19.0383 19 18.5296 19 17.9992V12.9992M17.586 3.58519C17.7705 3.39417 17.9912 3.2418 18.2352 3.13698C18.4792 3.03216 18.7416 2.97699 19.0072 2.97468C19.2728 2.97238 19.5361 3.02298 19.7819 3.12354C20.0277 3.2241 20.251 3.37261 20.4388 3.5604C20.6266 3.74818 20.7751 3.97148 20.8756 4.21728C20.9762 4.46307 21.0268 4.72643 21.0245 4.99199C21.0222 5.25755 20.967 5.51999 20.8622 5.76399C20.7574 6.008 20.605 6.22869 20.414 6.41319L11.828 14.9992H9V12.1712L17.586 3.58519Z"
        stroke="#FCFCFC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
