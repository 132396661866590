import { ReactElement, useState } from 'react';

import clsx from 'clsx';
import { AnimatePresence, motion, Variants } from 'framer-motion';

import { useHirePlanForm } from '../../../../../contexts/HirePlanFormProvider';
import { useDependentCountStore } from '../../../../../stores/useDependentCountStore';
import { CaretDownIcon } from '../../../../icons/CaretDownIcon';
import { PeopleIcon } from '../../../../icons/PeopleIcon';
import { TrashIcon } from '../../../../icons/TrashIcon';
import { DependentCardInfo } from './DependentCardInfo';

interface Props {
  dependent: {
    name: string;
    birthDate: string;
    civilStatus: string;
    gender: string;
    parentage: string;
    cns: string;
    cpf: string;
    rg: string;
    issuingAgency: string;
    issuingAgencyUF: string;
    uf: string;
    mothersName: string;
  };
  index: number;
}

export function DependentCard({ dependent, index }: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen() {
    setIsOpen(prevState => !prevState);
  }

  const variants: Variants = {
    open: { height: 'auto', opacity: 1, marginTop: 16 },
    closed: { height: 0, opacity: 0 },
  };

  const { setValue, watch } = useHirePlanForm();

  const dependents = watch('user.dependents');
  const removeDependent = useDependentCountStore(
    state => state.removeDependent,
  );

  function handleDelete() {
    setValue(
      'user.dependents',
      dependents?.filter((_, dependentIndex) => index !== dependentIndex),
    );
    removeDependent();
  }

  return (
    <div className="flex gap-4 mt-6 w-full">
      <div className="bg-white shadow-card rounded-2xl w-full overflow-hidden list-none">
        <button className="p-6 flex flex-col w-full" onClick={toggleIsOpen}>
          <div className="flex justify-between w-full">
            <div className="flex gap-2 items-center">
              <PeopleIcon />
              <h3 className="font-bold text-high-emphasis">{dependent.name}</h3>
            </div>
            <div>
              <CaretDownIcon
                className={clsx(
                  'stroke-neutral-main ',
                  isOpen ? 'rotate-180' : 'rotate-0',
                )}
              />
            </div>
          </div>
          <AnimatePresence>
            <motion.div
              variants={variants}
              initial="closed"
              animate={isOpen ? 'open' : 'closed'}
              className="block text-start text-medium-emphasis bg-[#F8F8FB] rounded-xl w-full"
            >
              <div className="p-6 w-full flex gap-8 flex-wrap items-center h-full">
                <DependentCardInfo title="Nome" text={dependent.name} />
                <DependentCardInfo
                  title="Data de nascimento"
                  text={dependent.birthDate}
                />
                <DependentCardInfo
                  title="Estado civil"
                  text={dependent.civilStatus}
                />
                <DependentCardInfo title="Gênero" text={dependent.gender} />
                <DependentCardInfo
                  title="Parentesco"
                  text={dependent.parentage}
                />
                <DependentCardInfo title="CNS" text={dependent.cns} />
                <DependentCardInfo title="CPF" text={dependent.cpf} />
                <DependentCardInfo title="RG" text={dependent.rg} />
                <DependentCardInfo
                  title="Expeditor"
                  text={dependent.issuingAgency}
                />
                <DependentCardInfo
                  title="UF"
                  text={dependent.issuingAgencyUF}
                />
                <DependentCardInfo
                  title="Nome da mãe"
                  text={dependent.mothersName}
                />
              </div>
            </motion.div>
          </AnimatePresence>
        </button>
      </div>
      <div className="pt-6">
        <button onClick={handleDelete}>
          <TrashIcon />
        </button>
      </div>
    </div>
  );
}
