import { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { BannerImage } from '../components/BannerImage';
import { PageHelmet } from '../components/PageHelmet';
import { QuestionAccordion } from '../components/QuestionAccordion';

export function FAQPage(): ReactElement {
  return (
    <div className="h-full min-h-[calc(100vh-549px)]">
      <PageHelmet title="FAQ | OdontoGroup" />

      <BannerImage page="faq" />
      <div className="min-[1059px]:w-[1011px] max-[1059px]:px-6 mx-auto">
        <section className="flex flex-col items-center justify-center min-[1059px]:pt-[116px] min-[1059px]:pb-[219px] py-20 ">
          <h1 className="text-[2rem] font-semibold text-primary-main text-center">
            Como podemos te ajudar?
          </h1>
          <h2 className="text-xl text-secondary-main">Perguntas frequentes.</h2>
          <ul className="w-full mt-16 flex flex-col gap-4">
            <QuestionAccordion
              title="Quais os planos odontológicos disponíveis?"
              answer={
                <>
                  <li>
                    <b>- OdontoClínico: </b>
                    Cobertura ampliada do rol da ANS. Tenha acesso a consultas,
                    limpeza, emergência 24h, prevenção, restauração, r.x,
                    odontopediatria, canal, extração de siso, prótese e mais de
                    200 procedimentos inclusos.
                  </li>
                  <li>
                    <b>- OdontoDoc: </b>
                    Toda a cobertura do plano Clínico, a instalação do aparelho
                    metálico e documentação inicial/final.
                  </li>
                  <li>
                    <b>- OdontoOrto: </b>
                    Toda a cobertura do plano Doc, a manutenção mensal do
                    aparelho e após o tratamento ortodôntico, o clareamento
                    convencional também está incluso.
                  </li>
                  <li>
                    <b>- OdontoPrótese: </b>
                    Toda a cobertura do plano Clínico, conta com um rol ampliado
                    para prótese, placa de bruxismo e clareamento convencional.
                  </li>
                  <li>
                    <b>- OdontoKids:</b> Plano exclusivo para crianças de 0 a 8
                    anos, com a mesma abrangência do nosso plano Clínico. Com
                    uma rede especializada em odontopediatria, garantimos que
                    seu filho não apenas receba os cuidados necessários, mas
                    também se sinta confortável e confiante nas idas ao
                    dentista.
                  </li>
                  <li>
                    <b>- Planos para Servidores Públicos:</b> Plano exclusivos
                    para servidores com desconto em folha. O plano ofertado
                    nessa modalidade é o OdontoClínico.
                  </li>
                </>
              }
            />
            <QuestionAccordion
              title="Quais as carências?"
              answer={
                <>
                  <li>
                    <b>- OdontoClínico: </b>
                    Emergência: 24h; Consultas: 30 dias; Radiologia, prevenção,
                    cirurgia, odontopediatria e dentística: 60 dias; Periodontia
                    e Endodontia: 90 dias; Prótese: 180 dias;
                  </li>
                  <li>
                    <b>- OdontoDoc: </b>
                    Emergência: 24h; Consulta, radiologia, instalação e
                    documentação do aparelho: 30 dias; Prevenção, cirurgia,
                    odontopediatria e dentística: 60 dias; Periodontia e
                    endodontia: 90 dias; Prótese: 180 dias;
                  </li>
                  <li>
                    <b>- OdontoOrto: </b>
                    Emergência: 24h; Consulta, radiologia e ortodontia: 30 dias;
                    Prevenção, cirurgia, odontopediatria e dentítisca: 60 dias;
                    Periodontia e endodontia: 90 dias; Prótese e clareamento:
                    180 dias;
                  </li>
                  <li>
                    <b>- OdontoPrótese: </b>
                    Emergência: 24h; Consultas 30 dias; Radiologia, prevenção,
                    odontopediatria e dentítica:60 dias; Cirurgia, periodontia,
                    endodontia: 90 dias; Prótese, clareamento e placa de
                    bruxismo: 180 dias.
                  </li>
                  <li>
                    <b>- OdontoKids:</b> Emergência: 24h; Consultas: 30 dias;
                    Radiologia, prevenção, cirurgia, odontopediatria e
                    dentística: 60 dias; Periodontia e Endodontia: 90 dias;
                    Prótese: 180 dias.
                  </li>
                  <li>
                    <b>- Plano Servidor Público:</b>
                    Para pagamento em desconto em folha, a carência é apenas
                    para prótese de 180 dias. Para débito em conta, as carências
                    são: Emergência: 24h; Consultas: 30 dias; Radiologia,
                    prevenção, cirurgia, odontopediatria e dentística: 60 dias;
                    Periodontia e Endodontia: 90 dias; Prótese: 180 dias.
                  </li>
                </>
              }
            />
            <QuestionAccordion
              title="Onde encontro a cobertura completa do meu plano?"
              answer={
                <>
                  Pelo aplicativo “OdontoGroup Associado”, acesse o menu no
                  canto inferior direito, em seguida em “meu plano” e depois em
                  “Cobertura”.
                  <br />
                  Você também pode acessar no
                  <Link className="text-primary-main" to={'/'} target="_blank">
                    {' '}
                    portal do beneficiário{', '}
                  </Link>
                  aqui no site.
                </>
              }
            />
            <QuestionAccordion
              title="A OdontoGroup tem clínica credenciada na minha cidade?"
              answer={
                <>
                  Você pode acessar a nossa lista de
                  <Link
                    className="text-primary-main"
                    to={process.env.REACT_APP_REDE_CREDENCIADA ?? '/'}
                    target="_blank"
                  >
                    {' '}
                    clínicas credenciadas{' '}
                  </Link>
                  e conferir a mais perto de você!
                </>
              }
            />
            <QuestionAccordion
              title="Qual a vigência do contrato?"
              answer="Para os planos individuais, o contrato tem a vigência de 12 meses. Não havendo solicitação formal de cancelamento por parte do beneficiário, o mesmo será renovado automaticamente por prazo indeterminado, sendo que não será cobrada nenhuma taxa ou qualquer outro valor por este fato."
            />
            <QuestionAccordion
              title="Onde acesso a minha carteirinha?"
              answer={
                <>
                  Ela pode ser acessada pelo aplicativo “OdontoGroup Associado”
                  que pode ser baixado na apple store e na play store, ou na
                  <Link
                    className="text-primary-main"
                    to={process.env.REACT_APP_S4E_ASSOCIADO ?? '/'}
                    target="_blank"
                  >
                    {' '}
                    área do beneficiário{' '}
                  </Link>
                  aqui no site.
                </>
              }
            />
            <QuestionAccordion
              title="Estou tentando acessar o aplicativo pela primeira vez, qual o meu login e senha?"
              answer={
                <>
                  <b>
                    Aplicativo OdontoGroup Associado ou OdontoGroup Corretor:
                  </b>
                  <br />
                  <br />
                  <p>
                    {' '}
                    O login é o seu CPF e a senha é a primeira letra do seu nome
                    (letra minúscula) seguidos dos três primeiros dígitos do seu
                    CPF.
                  </p>
                  <br />
                  <p>
                    Após o primeiro acesso, será necessário gerar uma nova senha
                    contendo: Número, caractere especial, letra maiúscula e
                    minúscula. Exemplo: Odonto24@
                  </p>

                  <br />
                  <b>Aplicativo OdontoGroup Prestador:</b>
                  <br />
                  <p>
                    Acesso Dentista:
                    <br />O login é o seu cpf e a senha os 3 primeiros dígitos
                    do CPF mais o seu nº do CRO.
                  </p>
                  <p>
                    Após o primeiro login, é necessário cadastrar uma nova senha
                    contendo de 8 a 10 números.
                  </p>
                  <p>
                    Acesso Clínica:
                    <br /> O login é o CNPJ da Clínica e a senha os 6 primeiros
                    números do CNPJ.
                  </p>
                  <p>
                    Após o primeiro login, é necessário cadastrar uma nova senha
                    contendo de 8 a 10 números.
                  </p>
                </>
              }
            />
            <QuestionAccordion
              title="Tem cobertura para clareamento?"
              answer="Sim! No plano OdontoOrto, após o tratamento ortodôntico, o clareamento convencional já está incluso no plano. Já no plano OdontoPrótese, você pode fazer o clareamento quando for indicado por um dentista."
            />
            <QuestionAccordion
              title="Qual será o vencimento do meu plano?"
              answer="Para pagamento no cartão de crédito, o vencimento será no dia da compra, no boleto e débito em conta, você escolherá a melhor data no checkout da compra."
            />
            <QuestionAccordion
              title="Quando poderei usar o meu plano?"
              answer={
                <>
                  <p>
                    Após a confirmação de pagamento da primeira mensalidade.
                  </p>
                  <li>
                    <b>- Cartão de crédito: </b>
                    até 48 horas;
                  </li>
                  <li>
                    <b>- Débito em conta: </b>
                    após o primeiro pagamento;
                  </li>
                  <li>
                    <b>- Boleto: </b> 3 a 5 dias uteis após o pagamento.
                  </li>
                </>
              }
            />
          </ul>
        </section>
      </div>
    </div>
  );
}
