import { ReactElement, ComponentProps } from 'react';

import clsx from 'clsx';

import QuickAccessButton from './Btn';

interface Props {
  className?: ComponentProps<'section'>['className'];
}

const QuickAccess = ({ className }: Props): ReactElement => (
  <section
    className={clsx(
      'min-h-[300px] max-[748px]:block text-center min-[1000px]:px-56 max-[999px]:px-[5%]',
      className,
    )}
  >
    <h1 className="text-[2.625rem] text-primary-main font-semibold max-[999px]:text-lg">
      Acesso Rápido
    </h1>
    <p className="text-secondary-main text-[1.75rem] max-[999px]:text-base">
      Encontre os serviços disponíveis para beneficiários ativos
    </p>
    <div className="flex gap-4 mt-14 justify-center items-center max-[999px]:flex-col">
      <QuickAccessButton
        text="Rede Credenciada"
        icon="/assets/images/icons/quick-access/network.png"
        href="/rede-credenciada"
      />
      <QuickAccessButton
        text="Acessando o App pela 1ª vez"
        icon="/assets/images/icons/quick-access/touch.png"
        href="#first-access"
        useAnchor
      />
      <QuickAccessButton
        text="Cobertura do plano"
        icon="/assets/images/icons/quick-access/dental.png"
      />
      <QuickAccessButton
        text="Negociações de mensalidade"
        icon="/assets/images/icons/quick-access/handshake.png"
        href="https://wa.me/+556140071087"
      />
      <QuickAccessButton
        text="Liberação de tratamentos"
        icon="/assets/images/icons/quick-access/file.png"
        href="https://wa.me/+556140071087"
      />
    </div>
  </section>
);

export default QuickAccess;
