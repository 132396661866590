import { ReactElement, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { useHirePlanForm } from '../../../../../contexts/HirePlanFormProvider';
import { paymentOptions } from '../data/paymentOptions';
import { PaymentOptionDescription } from './PaymentOptionDescription';
import { PaymentSelectorItem } from './PaymentSelectorItem';
import { PaymentCreditCardForm } from './PaymentOptionsForms/CreditCardForm';
import { PaymentBilletForm } from './PaymentOptionsForms/BilletForm';
import { PaymentDebitForm } from './PaymentOptionsForms/DebitForm';
import { useRouteLoaderData } from 'react-router-dom';
import { usePaymentStore } from '../../../../../stores/usePaymentStore';
import { useBoostedPlanDataStore } from '../../../../../stores/useBoostedPlanDataStore';

interface PaymentSelector {
  payment: number;
}

export function PaymentSelector(): ReactElement {
  const initialPlanData = useBoostedPlanDataStore(state => state.data);
  const setPaymentId = usePaymentStore(state => state.setId);
  const paymentId = usePaymentStore(state => state.id);

  const [grupoPagamento, setGrupoPagamento] = useState(Array);

  useEffect(() => {
    const grupos: number[] = [];
    initialPlanData.formasPagamento.map(paymentOption => {
      grupos.push(paymentOption.meioPagamentoIndividual.cd_gmeiopagto);
    });
    setGrupoPagamento(grupos);
  }, []);

  return (
    <>
      <div className="flex max-[1000px]:flex-col max-[1000px]:items-center w-full min-[748px]:mt-20">
        <nav
          className="min-[748px] w-full"
          aria-label="Opções de métodos de pagamento"
        >
          <ul className="flex flex-row gap-[14px] ">
            {paymentOptions.map(option => {
              for (const grupo of grupoPagamento) {
                if (grupo == option.id) {
                  return (
                    <PaymentSelectorItem
                      key={option.id}
                      label={option.label}
                      active={option.id === paymentId}
                      onClick={() => {
                        setPaymentId(option.id);
                      }}
                      Icon={option.Icon}
                      title={option.label}
                    />
                  );
                }
              }
            })}
          </ul>
        </nav>
      </div>
      {paymentId == 1 ? (
        <>
          <PaymentOptionDescription paymentId={paymentId} />
          <PaymentCreditCardForm />
        </>
      ) : paymentId == 3 ? (
        <>
          <PaymentOptionDescription paymentId={paymentId} />
          <PaymentBilletForm />
        </>
      ) : (
        paymentId == 2 && (
          <>
            <PaymentOptionDescription paymentId={paymentId} />
            <PaymentDebitForm />
          </>
        )
      )}
    </>
  );
}
