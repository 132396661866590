import { ReactElement } from 'react';

export function MenuIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="43"
      fill="none"
      viewBox="0 0 32 33"
    >
      <path
        fill="#5C6770"
        fillRule="evenodd"
        d="M6.667 9.833A1.333 1.333 0 018 8.5h16a1.333 1.333 0 010 2.667H8a1.333 1.333 0 01-1.333-1.334zm0 6.667A1.333 1.333 0 018 15.167h16a1.333 1.333 0 110 2.666H8A1.333 1.333 0 016.667 16.5zm0 6.667A1.333 1.333 0 018 21.833h16a1.333 1.333 0 010 2.667H8a1.334 1.334 0 01-1.333-1.333z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
