import { create } from 'zustand';

type PlanLoadingState = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export const usePlanLoadingStore = create<PlanLoadingState>(set => ({
  loading: true,
  setLoading: (loading: boolean) => set({ loading }),
}));
