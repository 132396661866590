import { ReactElement } from 'react';

interface Props {
  /* label: string; */
  value: string;
}

export function ItemPaymentOptionDescription({
  /* label, */
  value,
}: Props): ReactElement {
  return (
    <li>
      {/* <strong className="text-high-emphasis font-medium">{label}:</strong>{' '} */}
      <span className="text-neutral-600">{value}</span>
    </li>
  );
}
