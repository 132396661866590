import type { ReactElement, ComponentProps } from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

type Icon = 'building' | 'person' | 'deal' | 'dentist' | 'team';
interface Props {
  className?: ComponentProps<'div'>['className'];
  title1: string;
  text1: string;
  icon1?: Icon;
  useAnchor1?: boolean;
  blank1?: boolean;
  link1?: string;
  title2: string;
  text2: string;
  icon2?: Icon;
  link2?: string;
  useAnchor2?: boolean;
  blank2?: boolean;
}

export default function DoubleCard({
  className,
  title1,
  title2,
  text1,
  text2,
  icon1 = 'building',
  icon2 = 'deal',
  link1 = 'https://odontogroup.s4e.com.br/sys/?TipoUsuario=1',
  link2 = 'https://odontogroup.s4e.com.br/sys/?TipoUsuario=1',
  useAnchor1 = false,
  useAnchor2 = false,
  blank1 = false,
  blank2 = false,
}: Props): ReactElement {
  const Item1 = useAnchor1 ? 'a' : Link;
  const Item2 = useAnchor2 ? 'a' : Link;
  return (
    <div
      className={clsx(
        'shadow-card bg-neutral-white w-fit rounded-lg flex-1 flex justify-evenly p-3 max-[800px]:flex-col gap-3',
        className,
      )}
    >
      <Item1
        href={useAnchor1 ? link1 : undefined}
        to={(useAnchor1 ? undefined : link1) as any}
        target={blank1 ? '_blank' : undefined}
        rel={blank1 ? 'noreferrer' : undefined}
        className="border p-2 rounded-lg flex gap-2 min-[801px]:h-[108px] items-center cursor-pointer"
      >
        <img
          src={`/assets/images/icons/cards/${icon1}.svg`}
          className="h-[44px]"
        />
        <div>
          <p className="text-medium-emphasis text-sm font-bold max-[800px]:text-xs">
            {title1}
          </p>
          <h3 className="text-2xl text-primary-main max-[800px]:text-lg">
            {text1}
          </h3>
        </div>
      </Item1>

      <Item2
        href={useAnchor2 ? link2 : undefined}
        to={(useAnchor2 ? undefined : link2) as any}
        target={blank2 ? '_blank' : undefined}
        rel={blank2 ? 'noreferrer' : undefined}
        className="border p-2 rounded-lg flex gap-2 min-[801px]:h-[108px] items-center cursor-pointer"
      >
        <img
          src={`/assets/images/icons/cards/${icon2}.svg`}
          className="h-[44px]"
        />
        <div>
          <p className="text-medium-emphasis text-sm font-bold max-[800px]:text-xs">
            {title2}
          </p>
          <h3 className="text-2xl text-primary-main max-[800px]:text-lg">
            {text2}
          </h3>
        </div>
      </Item2>
    </div>
  );
}
