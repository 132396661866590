import { ReactElement } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  className?: string;
}

export function IdIcon({
  width = '33',
  height = '29',
  color = '#E6007E',
  className,
}: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 33 29"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13.167 6H4.833A3.333 3.333 0 001.5 9.333v15a3.334 3.334 0 003.333 3.334h23.334a3.333 3.333 0 003.333-3.334v-15A3.333 3.333 0 0028.167 6h-8.334m-6.666 0V4.333a3.333 3.333 0 016.666 0V6m-6.666 0a3.333 3.333 0 006.666 0M11.5 19.333a3.333 3.333 0 100-6.666 3.333 3.333 0 000 6.666zm0 0a5.006 5.006 0 014.717 3.334M11.5 19.333a5.001 5.001 0 00-4.717 3.334M21.5 14.333h5M21.5 21h3.333"
        className={className}
      ></path>
    </svg>
  );
}
