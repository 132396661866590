import { ReactElement } from 'react';

interface Props {
  className?: string;
}

export function DirectDebitIcon({ className }: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="30"
      fill="none"
      viewBox="0 0 39 30"
      className={`stroke-primary-main ${className}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M29.5 9V5a4 4 0 00-4-4h-20a4 4 0 00-4 4v12a4 4 0 004 4h4m4 8h20a4 4 0 004-4V13a4 4 0 00-4-4h-20a4 4 0 00-4 4v12a4 4 0 004 4zm14-10a4 4 0 11-8 0 4 4 0 018 0z"
      ></path>
    </svg>
  );
}
