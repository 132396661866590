import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AnimatePresence, motion, Variants } from 'framer-motion';

import { navigation } from '../../../config/navigation';
import { useMobileMenuStore } from '../../../stores/useMobileMenuStore';
import { useScreenStore } from '../../../stores/useScreenStore';
import { MenuItem } from './MenuItem';

const variants: Variants = {
  open: {
    y: '0',
  },
  closed: {
    y: '-100%',
  },
};

export function MobileMenu(): ReactElement {
  const isOpen = useMobileMenuStore(state => state.isOpen);
  const closeMenu = useMobileMenuStore(state => state.closeMenu);
  const { xl } = useScreenStore(state => state.screen);

  const location = useLocation();

  useEffect(() => {
    closeMenu();
  }, [closeMenu, location]);

  return (
    <AnimatePresence initial={false}>
      <motion.div
        animate={!xl && isOpen ? 'open' : 'closed'}
        variants={variants}
        className="fixed w-full bg-white top-0 left-0 z-30"
      >
        <div className="pt-[82px]">
          <ul>
            {navigation.map(item => (
              <MenuItem key={item.href} item={item} />
            ))}
          </ul>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
