import { ReactElement, ReactNode } from 'react';

import clsx from 'clsx';

interface RootProps {
  children: ReactNode;
}

function Root({ children }: RootProps): ReactElement {
  return (
    <ol className="flex gap-8 relative mb-16">
      <div className="w-[465px] absolute top-4 left-1/2 transform -translate-x-1/2 border border-[#D1D1DE] border-dashed rounded-none"></div>
      {children}
    </ol>
  );
}

interface ItemProps {
  text: string;
  number: number;
  active?: boolean;
}

function Item({ text, number, active = false }: ItemProps): ReactElement {
  return (
    <li className="flex flex-col items-center gap-1 z-10">
      <span
        className={clsx(
          'rounded-full h-8 w-8 flex justify-center items-center font-semibold',
          active
            ? 'bg-primary-main text-white'
            : 'bg-primary-container text-primary-main',
        )}
      >
        {number}
      </span>
      <span
        className={clsx(
          'text-sm ',
          active ? 'text-primary-main' : 'text-medium-emphasis',
        )}
      >
        {text}
      </span>
    </li>
  );
}

export const HirePlanFormBreadcrumb = {
  Root,
  Item,
};
