import { ReactElement } from 'react';

interface Props {
  width?: string;
  height?: string;
  className?: string;
}

export function HeartIcon({
  height = '15',
  width = '17',
  className,
}: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 17 15"
    >
      <path
        className={className}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.285 3.481a3.75 3.75 0 016.117-1.217L8.5 3.363l1.098-1.099a3.75 3.75 0 015.304 5.304L8.5 13.969 2.098 7.568a3.75 3.75 0 01-.813-4.087z"
      ></path>
    </svg>
  );
}
