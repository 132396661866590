import { forwardRef, SelectHTMLAttributes } from 'react';

import clsx from 'clsx';

import { CaretDownIcon } from './icons/CaretDownIcon';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  options: string[];
  placeholder?: string;
  optionsFull?: any;
  onChange?: any;
  errorMessage?: string;
}

export const Select = forwardRef<HTMLSelectElement, Props>(
  (
    {
      label,
      options,
      placeholder,
      onChange,
      optionsFull,
      errorMessage,
      ...props
    },
    ref,
  ) => {
    return (
      <fieldset className="flex flex-col gap-1 w-full">
        <label htmlFor={label} className="text-high-emphasis">
          {label}
        </label>
        <div className="relative">
          <select
            ref={ref}
            id={label}
            defaultValue=""
            onChange={onChange}
            className={clsx(
              'w-full px-4 py-3 rounded-lg text-medium-emphasis border appearance-none focus:ring-1 focus:outline-none',
              errorMessage
                ? 'border-destructive-main focus:ring-destructive-main'
                : 'border-neutral-lighter focus:ring-primary-main',
            )}
            {...props}
          >
            {placeholder && (
              <option value="" disabled>
                {placeholder}
              </option>
            )}
            {optionsFull
              ? optionsFull.map((option, index) => (
                  <option key={option.Id} value={option.Id}>
                    {option.Sigla || option.Nome}
                  </option>
                ))
              : options.map((option, index) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}

            {/* {texts ? texts.map((text, index2) => text[index2]) : option} */}
          </select>
          <CaretDownIcon className="absolute right-4 bottom-[13px] stroke-neutral-400" />
        </div>
        {errorMessage && (
          <span className="text-xs text-destructive-dark mt-1">
            {errorMessage}
          </span>
        )}
      </fieldset>
    );
  },
);
