import { create } from 'zustand';

interface Screen {
  '3xl'?: boolean;
  xl?: boolean;
  mobile?: boolean;
}

type ScreenState = {
  screen: Screen;
  setScreen: (screen: Screen) => void;
};

export const useScreenStore = create<ScreenState>(set => ({
  screen: {},
  setScreen: (screen: Screen) => set({ screen }),
}));
