import { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useMobileMenuStore } from '../../stores/useMobileMenuStore';
import { useScreenStore } from '../../stores/useScreenStore';
import { Button } from '../Button';
import { CartIcon } from '../icons/CartIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { MobileMenu } from './MobileMenu';
import { Navbar } from './Navbar';
import { HashLink } from 'react-router-hash-link';

export function Header(): ReactElement {
  const { xl } = useScreenStore(state => state.screen);
  const toggleMenu = useMobileMenuStore(state => state.toggleMenu);
  const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  useEffect(() => {
    const embedScript = document.createElement('script');
    embedScript.src =
      'https://app.acessibilizar.com.br/embed-scripts/7e4a1c6d-2511-425c-8e59-6883b3fb3714';
    embedScript.type = 'text/javascript';
    embedScript.async = true;
    embedScript.setAttribute(
      'embed-id',
      '7e4a1c6d-2511-425c-8e59-6883b3fb3714',
    );

    document.head.appendChild(embedScript);

    const script = document.createElement('script');
    script.src =
      '//tag.goadopt.io/injector.js?website_code=f595d538-f2e4-4058-b251-9c904a94a14a';
    script.async = true;
    script.className = 'adopt-injector';

    const meta = document.createElement('meta');
    meta.name = 'adopt-website-id';
    meta.content = 'f595d538-f2e4-4058-b251-9c904a94a14a';

    document.head.appendChild(meta);

    document.head.appendChild(script);

    const scriptGA = document.createElement('script');
    scriptGA.async = true;
    scriptGA.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    document.head.appendChild(scriptGA);

    // Configura o Google Analytics (gtag)
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      window.dataLayer.push(args);
    }
    gtag('js', new Date());
    gtag('config', GA_TRACKING_ID);

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      document.head.removeChild(embedScript);
      document.head.removeChild(script);
      document.head.removeChild(meta);
      document.head.removeChild(scriptGA);
    };
  }, []);

  return (
    <>
      <header className="z-40 fixed top-0 w-full h-[82px] flex items-center bg-[#F9F9F9] xl:rounded-b-3xl xl:shadow-card border-b border-b-neutral-lightest">
        <div className="xl:max-w-[1621px] 3xl:px-0 px-6  w-full mx-auto flex justify-between items-center h-[50px]">
          <Link to="/">
            <img src="/assets/logos/odontogroup.png" alt="Odontogroup" />
          </Link>
          {xl ? (
            <>
              <Navbar />
              <HashLink to={'#individual'}>
                <Button text="Contratar plano" Icon={CartIcon} />
              </HashLink>
            </>
          ) : (
            <button onClick={toggleMenu}>
              <MenuIcon />
            </button>
          )}
        </div>
      </header>
      <MobileMenu />
    </>
  );
}
