import { ReactElement, useEffect, useState } from 'react';

import { BannerImage } from '../components/BannerImage';
import { HirePlanFormSection } from '../components/HirePlanForm/HirePlanFormSection';
import { HirePlanMobileArticleHeader } from '../components/HirePlanForm/mobile/HirePlanMobileArticleHeader';
import { PurchaseSummary } from '../components/HirePlanForm/PurchaseSummary';
import { PageHelmet } from '../components/PageHelmet';
import { useWindowSizeStore } from '../stores/useWindowSizeStore';
import {
  json,
  useLoaderData,
  useParams,
  useRouteLoaderData,
} from 'react-router-dom';
import { useBoostedPlanDataStore } from '../stores/useBoostedPlanDataStore';
import { useNortheastPlanDataStore } from '../stores/useNortheastPlanDataStore';

export function HirePlanPage(): ReactElement {
  const individualPlanData: any = useRouteLoaderData('token');
  const bussinessPlanData: any = useRouteLoaderData('token-empresa');
  let initialPlanData = individualPlanData;
  if (individualPlanData == undefined) {
    initialPlanData = bussinessPlanData;
  } else {
    initialPlanData = individualPlanData;
  }

  const boostedPlanData = useBoostedPlanDataStore(state => state.data);
  const northeastPlanData = useNortheastPlanDataStore(state => state.data);
  const width = useWindowSizeStore(state => state.width);

  const [data, setData] = useState(initialPlanData);

  useEffect(() => {
    console.log(Object.keys(boostedPlanData).length === 0);
    if (Object.keys(boostedPlanData).length !== 0 && boostedPlanData != data) {
      setData(boostedPlanData);
    } else if (
      Object.keys(northeastPlanData).length !== 0 &&
      northeastPlanData != data
    ) {
      setData(northeastPlanData);
      console.log(data);
    }
  }, [boostedPlanData, northeastPlanData]);

  const hirePlanIndividualSections =
    width >= 1086
      ? [
          <HirePlanFormSection key="hirePlanFormSection" />,
          <PurchaseSummary key="purchaseSummary" />,
        ]
      : [
          <PurchaseSummary key="purchaseSummary" />,
          <HirePlanFormSection key="hirePlanFormSection" />,
        ];

  const hirePlanBussinessSections =
    width >= 1086
      ? [
          <HirePlanFormSection key="hirePlanFormSection" />,
          <PurchaseSummary key="purchaseSummary" />,
        ]
      : [
          <PurchaseSummary key="purchaseSummary" />,
          <HirePlanFormSection key="hirePlanFormSection" />,
        ];

  return (
    <div className="overflow-hidden">
      <PageHelmet title="Contratar Plano | OdontoGroup" />

      <BannerImage page="plan" imgSource="/assets/images/plan-banner.jpg" />
      <div className="">
        <article className="-mb-20 bg-transparent min-[748px]:-mt-[326px] -mt-[57px] max-w-[1551px] max-[1599px]:px-6 mx-auto">
          <h1 className="2xl:text-[2.5rem] sm:text-3xl text-lg min-[748px]:text-center min-[1086px]:text-start text-white mb-[60px] px-6">
            Plano{' '}
            <span className="font-semibold">
              {data.produtoComercial.nm_prodcomerc}
            </span>
          </h1>
          <div className="flex 2xl:gap-16 min-[748px]:gap-8 gap-16 max-[1085px]:flex-col">
            <HirePlanMobileArticleHeader />
            {initialPlanData.type == 'individual'
              ? hirePlanIndividualSections
              : hirePlanBussinessSections}
          </div>
        </article>
      </div>
    </div>
  );
}
