import { create } from 'zustand';

import { breadcrumbSteps } from '../components/HirePlanForm/data/breadcrumb';

type StepState = {
  step: number;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  clearStep: () => void;
};

export const useHirePlanFormStepStore = create<StepState>(set => ({
  step: 1,
  goToNextStep: () =>
    set(state => {
      if (state.step < breadcrumbSteps.length) {
        window.scrollTo({ top: 0 });
        return { step: state.step + 1 };
      }

      return { step: state.step };
    }),
  goToPreviousStep: () =>
    set(state => {
      if (state.step > 1) {
        window.scrollTo({ top: 0 });
        return { step: state.step - 1 };
      }

      return { step: state.step };
    }),
  clearStep: () =>
    set(state => {
      if (state.step > 1) {
        window.scrollTo({ top: 0 });
        return { step: 0 };
      }

      return { step: state.step };
    }),
}));
