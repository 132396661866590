import { ReactElement } from 'react';

import { useWindowSizeStore } from '../../../stores/useWindowSizeStore';
import { Button } from '../../Button';
import { LeadingIcon } from '../../icons/LeadingIcon';

export function HirePlanMobileArticleHeader(): ReactElement | null {
  const width = useWindowSizeStore(state => state.width);

  if (width < 748) {
    return (
      <header className="flex flex-col justify-center items-center gap-4">
        <div className="h-[72px] w-full bg-[#F8F8FB] flex justify-center items-center px-5 rounded-xl">
          {/* <h2 className="text-base font-bold text-primary-main">Plano Orto</h2> */}
          <Button text="Ver coberturas" Icon={LeadingIcon} intent="tertiary" />
        </div>
        <img src="/assets/logos/odontogroup-azul.png" alt="" />
      </header>
    );
  }

  return null;
}
