import { create } from 'zustand';

type WindowSizeState = {
  width: number;
  height: number;
  setSize: (width: number, height: number) => void;
};

export const useWindowSizeStore = create<WindowSizeState>(set => ({
  width: typeof window !== 'undefined' ? window.innerWidth : 0,
  height: typeof window !== 'undefined' ? window.innerHeight : 0,
  setSize: (width: number, height: number) => set({ width, height }),
}));
