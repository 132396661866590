import { ReactElement, useEffect } from 'react';

import { BannerImage } from '../components/BannerImage';
import { PageHelmet } from '../components/PageHelmet';
import { ProposalArticle } from '../components/ProposalArticle';
import { useLocation } from 'react-router-dom';
import { useHirePlanFormStepStore } from '../stores/useHirePlanFormStepStore';

export function ProposalPage(): ReactElement {
  const clearStep = useHirePlanFormStepStore(state => state.clearStep);
  const data = useLocation();

  useEffect(() => {
    clearStep();
  });

  return (
    <>
      <PageHelmet title="Proposta | OdontoGroup" />

      <BannerImage
        imgSource="/assets/images/banner-proposal.jpg"
        page="proposal"
      />
      <div className="max-[1524px]:px-4 h-full">
        <ProposalArticle data={data.state} />
      </div>
    </>
  );
}
