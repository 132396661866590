import { ReactElement, useState } from 'react';

import { ItemPaymentOptionDescription } from './ItemPaymentOptionDescription';
import { useRouteLoaderData } from 'react-router-dom';

import { InfoIcon } from '../../../../../icons/InfoIcon';

export function PaymentOptionDescription(props: {
  paymentId: number;
}): ReactElement {
  const data: any = useRouteLoaderData('token');
  return (
    <div className="max-[1000px]:flex-col max-[1000px]:items-center min-[748px]:mt-6 bg-neutral-100 min-[748px]:p-6 min-[748px]:pt-4 w-full flex flex-col justify-center">
      <span className="text-primary-main font-bold">Carência</span>
      <ul className="flex flex-col text-sm gap-2 mt-2 mb-3">
        {data.carencias.map((carencias: any) => (
          <ItemPaymentOptionDescription
            key={carencias.carencia.id_carencia}
            value={carencias.carencia.nm_descricaocarencia}
          />
        ))}
      </ul>
      {props.paymentId == 1 && (
        <div className="flex items-center border border-info-main bg-info-container rounded-md w-full py-3 px-2">
          <InfoIcon className="items-center" />
          <span className="text-info-dark text-sm ml-2">
            Crédito recorrente não utiliza o limite do cartão de crédito.
          </span>
        </div>
      )}
    </div>
  );
}
