import { ButtonHTMLAttributes, FC, ReactElement } from 'react';

import { cva, VariantProps } from 'class-variance-authority';

const buttonStyles = cva(
  'flex justify-center items-center text-sm py-3 px-4 rounded-lg flex gap-2 focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-center h-12',
  {
    variants: {
      intent: {
        primary:
          'text-neutral-with bg-primary-main hover:bg-primary-dark focus:ring-primary-light focus:ring-offset-white',
        secondary:
          'text-primary-main border-2 border-primary-main bg-transparent hover:bg-primary-container focus:ring-primary-light focus:ring-offset-white',
        tertiary:
          'group text-primary-main border-2 border-transparent bg-transparent hover:bg-primary-container hover:text-primary-main focus:ring-primary-main',
        quaternary:
          'group text-white border-2 border-white bg-transparent hover:bg-primary-container hover:text-primary-main focus:ring-white focus:ring-offset-white',
      },
    },
    defaultVariants: {
      intent: 'primary',
    },
  },
);

interface Props
  extends VariantProps<typeof buttonStyles>,
    ButtonHTMLAttributes<HTMLButtonElement> {
  text: any;
  Icon?: FC<{ className?: string }>;
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
}

export function Button({
  text,
  intent,
  Icon,
  disabled,
  className,
  iconClassName,
  ...props
}: Props): ReactElement {
  return (
    <button
      disabled={disabled}
      className={`${buttonStyles({ intent })} ${className}`}
      {...props}
    >
      {Icon && <Icon className={iconClassName} />}
      <span className="inline-block uppercase font-bold tracking-normal">
        {text}
      </span>
    </button>
  );
}
