import { ReactElement } from 'react';

interface Props {
  percent: number;
  text: string;
}

export function BenefitCard({ percent, text }: Props): ReactElement {
  return (
    <li className="w-[229px] h-[190px] bg-white rounded-xl shadow-card p-4 flex flex-col justify-start items-center max-[701px]:w-full max-[701px]:justify-center">
      <strong className="text-4xl text-secondary-main mb-1">
        Até {percent}%
      </strong>
      <p className="text-xl max-[701px]:text-lg text-neutral-500">{text}</p>
    </li>
  );
}
