export const footerNavigation = [
  {
    name: 'Empresarial',
    link: process.env.REACT_APP_S4E_EMPRESA ?? '/',
  },
  {
    name: 'Transparência',
    link: process.env.REACT_APP_TRANSPARENCIA ?? '/',
  },
  {
    name: 'Rede Credenciada',
    link: process.env.REACT_APP_REDE_CREDENCIADA ?? '/',
  },
  {
    name: 'Fale com a OdontoGroup',
    link: process.env.REACT_APP_FALE_COM_A_ODONTOGROUP ?? '/',
  },
  {
    name: 'Ouvidoria',
    link: '/ouvidoria',
  },
  {
    name: 'Institucional',
    link: process.env.REACT_APP_INSTITUCIONAL ?? '/',
  },
];
