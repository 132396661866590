import { ReactElement } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  className?: string;
}

export function CartIcon({
  height = '20',
  width = '20',
  color = '#ffffff',
  className,
}: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1h2l.4 2m0 0H19l-4 8H5M3.4 3L5 11m0 0l-2.293 2.293c-.63.63-.184 1.707.707 1.707H15m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
        className={className}
      ></path>
    </svg>
  );
}
