import { ReactElement } from 'react';

interface Props {
  width?: string;
  height?: string;
  className?: string;
}

export function HomeIcon({
  height = '14',
  width = '15',
  className,
}: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 15 14"
    >
      <path
        className={className}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
        d="M1.5 7l1.333-1.333m0 0L7.5 1l4.667 4.667m-9.334 0v6.666A.667.667 0 003.5 13h2m6.667-7.333L13.5 7m-1.333-1.333v6.666A.667.667 0 0111.5 13h-2m-4 0a.666.666 0 00.667-.667V9.667A.667.667 0 016.833 9h1.334a.667.667 0 01.666.667v2.666A.667.667 0 009.5 13m-4 0h4"
      ></path>
    </svg>
  );
}
