import { ReactElement, useEffect, useState } from 'react';

import { useRouteLoaderData } from 'react-router-dom';

import { InfoIcon } from '../../../../../icons/InfoIcon';
import { Input } from '../../../../../Input';
import { useHirePlanForm } from '../../../../../../contexts/HirePlanFormProvider';
import { Select } from '../../../../../Select';

export function PaymentCreditCardForm(): ReactElement {
  const data: any = useRouteLoaderData('token');

  const { register, handleSubmit, goToNextForm, errors, watch } =
    useHirePlanForm();

  const cardName = watch('payment.name');
  const cardNumber = watch('payment.number');
  const cardExpireDate = watch('payment.expireDate');

  return (
    <div className="max-[1000px]:flex-col max-[1000px]:items-center min-[748px]:mt-6 min-[748px]:p-6 min-[748px]:pt-4 w-full flex flex-col justify-center">
      <span className="text-primary-main text-xl font-medium mb-8">
        Dados do cartão
      </span>
      {/* <div className="flex flex-col text-sm gap-2 mt-2 mb-3">
        <span className="text-info-dark text-sm ml-2">
          Crédito recorrente não utiliza o limite do cartão de crédito.
        </span>
      </div> */}
      <div className="grid min-[748px]:grid-cols-2">
        <div className="grid grid-cols-1 gap-6">
          <div className="grid grid-cols-1 gap-8">
            <Input
              label="Número do cartão"
              activateMask={true}
              mask="9999 9999 9999 9999"
              placeholder="0000 0000 0000 0000"
              value={cardNumber?.replace(/\s+/g, '')}
              errorMessage={errors.payment?.number?.message}
              {...register('payment.number')}
            />
          </div>
          <div className="grid grid-cols-1 gap-8">
            <Input
              label="Nome impresso no cartão"
              placeholder="Nome"
              errorMessage={errors.payment?.name?.message}
              {...register('payment.name')}
            />
          </div>
          <div className="grid grid-cols-2 gap-8">
            <Input
              label="Validade"
              activateMask={true}
              mask="99/99"
              placeholder="mm/aa"
              errorMessage={errors.payment?.expireDate?.message}
              {...register('payment.expireDate')}
            />
            <Input
              label="CVV"
              className="w-full"
              activateMask={true}
              mask="999"
              placeholder="CVV"
              errorMessage={errors.payment?.cvv?.message}
              {...register('payment.cvv')}
            />
          </div>
          <div className="grid grid-cols-1 gap-8">
            <Select
              label="Parcelas"
              options={['à vista']}
              placeholder="N° de parcelas"
              errorMessage={errors.payment?.instalment?.message}
              {...register('payment.instalment')}
            />
          </div>
        </div>
        <div className="grid grid-cols-1">
          <div className="flex h-40 ml-8 max-[748px]:mx-8 self-center rounded-md bg-gradient-to-r from-secondary-main via-primary-card to-primary-main ">
            <div className="text-white self-end px-2 pb-3 w-full">
              <span className="text-white">
                {cardNumber == null || cardNumber == ''
                  ? '0000 0000 0000 0000'
                  : cardNumber}
              </span>
              <div className="flex justify-between w-full ">
                <span className="text-white">
                  {cardName == null || cardName == ''
                    ? 'XXXXXX XXXXXX'
                    : cardName}
                </span>
                <span className="text-white self-center">
                  {cardExpireDate == null || cardExpireDate == ''
                    ? '00/00'
                    : cardExpireDate}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="h-[108px] w-full bg-[#F7F6FF] rounded-xl flex flex-col justify-center items-center gap-3">
        <h3 className="text-xl font-semibold text-primary-main">
          Valor mensal
        </h3>
        <strong className="text-secondary-main font-normal">
          <span className="font-bold">R$</span>{' '}
          <span className="text-[2rem] font-bold">26,90</span> por mês
        </strong>
      </div> */}
      {/* <div className="flex items-center border border-info-main bg-info-container rounded-md w-full py-3 px-2">
        <InfoIcon className="items-center" />
        <span className="text-info-dark text-sm ml-2">
          Crédito recorrente não utiliza o limite do cartão de crédito.
        </span>
      </div> */}
    </div>
  );
}
