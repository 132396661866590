import { ReactElement, useState } from 'react';

import { personalPlans } from './data/plans';
import { PlanCard } from './PlanCard';

export function PersonalPlans(): ReactElement {
  return (
    <section id="individual" className="min-[1548px]:h-[800px] relative">
      <div className="bg-primary-main absolute w-full top-0 -z-10 max-[1548px]:h-[calc(100%_-_180px)] h-full" />
      <div className="mx-auto min-[1548px]:w-[1500px] max-[1548px]:px-6">
        <div className="flex flex-col justify-center items-center text-center pt-24">
          <h2 className="text-neutral-with min-[1548px]:text-[2.625rem] min-[701px]:text-[2rem] text-lg font-semibold mb-3">
            Planos para você e sua família
          </h2>
          <p className="min-[1548px]:text-2xl min-[1548px]:w-[1084px] min-[701px]:text-xl text-base text-neutral-with text-center">
            Conheça nossos planos e convênios, escolha a opção que se encaixa
            com o seu perfil. <br />
            Temos um plano exclusivo para você.
          </p>
        </div>

        <ul className="min-[1300px]:flex mt-[88px] grid grid-cols-4 max-[1300px]:grid-cols-2 max-[701px]:grid-cols-1 gap-6 list-none">
          {personalPlans.map(plan => (
            <PlanCard plan={plan} key={plan.name} type={'individual'} />
          ))}
        </ul>
      </div>
    </section>
  );
}
