import { ReactElement } from 'react';

import { LabelledInformationProposal } from '../LabelledInformationProposal';

type Props = {
  data: {
    nomePlano: string;
    quantidadeVidas: string;
    valorPagamento: string;
  };
};

export function PaymentSummaryProposal(state: Props): ReactElement {
  return (
    <section className="p-4 h-full rounded-xl w-full border border-[#D8D8D8] flex max-[748px]:flex-col justify-between gap-6">
      <div className="flex flex-col max-w-[347px]">
        <h2 className="text-xl text-[#0B0B0B] mb-4">Resumo da compra</h2>
        <ul className="flex flex-wrap w-full gap-x-8 gap-y-3">
          <LabelledInformationProposal
            label="PLano escolhido"
            value={state.data.nomePlano}
          />
          <LabelledInformationProposal
            label="QTD de vidas"
            value={state.data.quantidadeVidas}
          />
          <LabelledInformationProposal
            label="Valor plano"
            value={state.data.valorPagamento}
          />
          {/* <LabelledInformationProposal
            label="Valor Telemedicina"
            value="R$ 3,99"
          /> */}
        </ul>
      </div>
      <div className="max-[748px]:py-3 min-[748px]:max-w-[220px] w-full rounded-xl bg-[#F8F8FB] flex flex-col items-center justify-center gap-3 min-[748px]:min-h-[140px]">
        <label htmlFor="totalValue" className="block text-secondary-main -mb-2">
          Valor total
        </label>
        <span id="totalValue" className="text-[#394146]">
          <strong className="text-[2rem]"> {state.data.valorPagamento} </strong>
        </span>
        <span className="-mt-5 text-[#394146]">por mês</span>
      </div>
    </section>
  );
}
