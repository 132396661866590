import { ReactElement } from 'react';

interface Props {
  maxTextSize?: number;
  title: string;
  text: string;
}

export default function SolTextBox({
  title,
  text,
  maxTextSize,
}: Props): ReactElement {
  return (
    <div className="bg-primary-main p-5 pb-0 flex items-center rounded-xl max-[999px]:flex-col">
      <img
        className="min-[1000px]:-mt-28 max-[999px]:max-h-[180px] max-[999px]:ml-auto"
        src="/assets/images/sol/sol-crossed-arms.png"
      />
      <div
        className="text-neutral-with text-2xl max-[999px]:-order-1 max-[999px]:text-lg"
        style={{ maxWidth: maxTextSize }}
      >
        <b>{title}</b>
        <p>{text}</p>
      </div>
    </div>
  );
}
