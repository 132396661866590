export const breadcrumbSteps = [
  {
    step: 1,
    name: 'Cadastro beneficiário',
  },
  {
    step: 2,
    name: 'Dependentes',
    description: 'Você pode adicionar quantos dependentes desejar',
  },
  {
    step: 3,
    name: 'Turbine seu plano',
    description: 'Você pode adicionar coberturas ao plano selecionado',
  },
  {
    step: 4,
    name: 'Pagamento',
    description:
      'Escolha uma forma de pagamento  e um link de pagamento será enviado para seu email após o envio desse formulário.',
  },
];

export const bussinessBreadcrumbSteps = [
  {
    step: 1,
    name: 'Dados do responsável',
  },
  {
    step: 2,
    name: 'Dados da empresa',
  },
  {
    step: 3,
    name: 'Funcionários',
    description: 'Você pode adicionar quantos funcionários desejar',
  },
  {
    step: 4,
    name: 'Pagamento',
    description:
      'Escolha uma forma de pagamento  e um link de pagamento será enviado para seu email após o envio desse formulário.',
  },
];
