import { ReactElement } from 'react';

import { navigation } from '../../../config/navigation';
import { NavbarItem } from './NavbarItem';

export function Navbar(): ReactElement {
  return (
    <nav className="h-full">
      <ul className="h-full flex gap-6">
        {navigation.map(item => (
          <NavbarItem item={item} key={item.href} />
        ))}
      </ul>
    </nav>
  );
}
