import {
  IndividualWithShortageTokens,
  IndividualWithoutShortageTokens,
  BussinessTypeRangeLives1Tokens,
  BussinessTypeRangeLives2Tokens,
} from '../../../utils/Enums/Tokens';
interface Plan {
  type?: string;
  name: string;
  iconSource: string;
  category: Category[];
  covers: string[];
  coversPlus?: string[];
  shortage?: string;
  typeRangeLives?: number;
}

interface Category {
  type: string;
  price: string;
  token: string;
}

export const personalPlans: Plan[] = [
  {
    name: 'OdontoClínico',
    iconSource: '/assets/images/icons/planos/clinico.svg',
    category: [
      {
        type: 'Com Carencia',
        price: '41,90',
        token: IndividualWithShortageTokens.CLINICO, // 709
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
  },
  {
    name: 'OdontoDoc',
    iconSource: '/assets/images/icons/planos/doc.svg',
    category: [
      {
        type: 'Com Carencia',
        price: '46,00',
        token: IndividualWithShortageTokens.DOC, // 791
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
    coversPlus: ['+ Documentação para aparelho', '+ Aparelho metálico'],
  },
  {
    name: 'OdontoOrto',
    iconSource: '/assets/images/icons/planos/orto.svg',
    category: [
      {
        type: 'Com Carencia',
        price: '145,00',
        token: IndividualWithShortageTokens.ORTO, // 713
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
    coversPlus: [
      '+ Documentação para aparelho',
      '+ Aparelho metálico',
      '+ Manutenções mensais',
      '+ Clareamento caseiro',
    ],
  },
  {
    name: 'OdontoPrótese',
    iconSource: '/assets/images/icons/planos/protese.svg',
    category: [
      {
        type: 'Com Carencia',
        price: '105,00',
        token: IndividualWithShortageTokens.PROTESE, //796
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
    coversPlus: [
      '+ Clareamento caseiro',
      '+ Prótese total',
      '+ Prótese removível',
      '+ Placa de bruxismo',
    ],
  },
];

// clinico 24.50 - > 2 a 29 //719
// clinico 21.90 - > 30 a 199 //721

// Doc 29.90 - > 2 a 29 //864
// Doc 27.80 - > 30 a 199 //866

// Orto 121.00 - > 2 a 29 //720
// Orto 99.90 - > 30 a 199 //722

export const businessPlans: Plan[] = [
  {
    name: 'OdontoClínico',
    iconSource: '/assets/images/icons/planos/clinico.svg',
    category: [
      {
        type: '2 a 29 Vidas',
        price: '24,50',
        token: BussinessTypeRangeLives1Tokens.CLINICO,
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
    shortage: 'Prótese: 180 dias',
    typeRangeLives: 1,
  },
  {
    name: 'OdontoDoc',
    iconSource: '/assets/images/icons/planos/doc.svg',
    category: [
      {
        type: '2 a 29 Vidas',
        price: '29,90',
        token: BussinessTypeRangeLives1Tokens.DOC,
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
    coversPlus: ['+ Documentação para aparelho', '+ Aparelho metálico'],
    shortage: 'Prótese: 180 dias',
    typeRangeLives: 1,
  },
  {
    name: 'OdontoOrto',
    iconSource: '/assets/images/icons/planos/orto.svg',
    category: [
      {
        type: '2 a 29 Vidas',
        price: '121,00',
        token: BussinessTypeRangeLives1Tokens.ORTO,
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
    coversPlus: [
      '+ Aparelho metálico',
      '+ Manutenções mensais',
      '+ Clareamento caseiro',
    ],
    shortage: 'Prótese: 180 dias',
    typeRangeLives: 1,
  },
  {
    name: 'OdontoClínico',
    iconSource: '/assets/images/icons/planos/clinico.svg',
    category: [
      {
        type: '30 a 199 Vidas',
        price: '21,90',
        token: BussinessTypeRangeLives2Tokens.CLINICO,
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
    shortage: 'Prótese: 180 dias',
    typeRangeLives: 2,
  },
  {
    name: 'OdontoDoc',
    iconSource: '/assets/images/icons/planos/doc.svg',
    category: [
      {
        type: '30 a 199 Vidas',
        price: '27,80',
        token: BussinessTypeRangeLives2Tokens.DOC,
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
    coversPlus: ['+ Documentação para aparelho', '+ Aparelho metálico'],
    shortage: 'Prótese: 180 dias',
    typeRangeLives: 2,
  },
  {
    name: 'OdontoOrto',
    iconSource: '/assets/images/icons/planos/orto.svg',
    category: [
      {
        type: '30 a 199 Vidas',
        price: '99,90',
        token: BussinessTypeRangeLives2Tokens.ORTO,
      },
    ],
    covers: [
      'Limpeza',
      'Restauração',
      'Tratamento de Canal',
      'Extrações',
      'Raio X',
      'Emergência 24h',
      'Prótese',
      '+ 200 procedimentos',
    ],
    coversPlus: [
      '+ Aparelho metálico',
      '+ Manutenções mensais',
      '+ Clareamento caseiro',
    ],
    shortage: 'Prótese: 180 dias',
    typeRangeLives: 2,
  },
];

export const publicServantsPlans: Plan[] = [
  {
    type: 'Servidor Público',
    name: 'Federal',
    iconSource: '/assets/images/icons/planos/clinico.svg',
    category: [
      {
        type: 'Com Carencia',
        price: '31,90',
        token: 'DwIJH0w',
      },
    ],
    covers: [
      'Limpeza',
      'Restaurações',
      'Tratamento de canal',
      'Extrações',
      'Coroa em resina dentes anteriores ',
      '+ 200 procedimentos',
    ],
    coversPlus: ['+ Documentação para aparelho', '+ Aparelho metálico'],
    shortage: 'Prótese: 180 dias',
  },
  {
    type: 'Servidor Público',
    name: 'Estadual /PE',
    iconSource: '/assets/images/icons/planos/clinico.svg',
    category: [
      {
        type: 'Com Carencia',
        price: '31,90',
        token: 'DwIJH0w',
      },
    ],
    covers: [
      'Limpeza',
      'Restaurações',
      'Tratamento de canal',
      'Extrações',
      'Coroa em resina dentes anteriores ',
      '+ 200 procedimentos',
    ],
    coversPlus: ['+ Documentação para aparelho', '+ Aparelho metálico'],
    shortage: 'Prótese: 180 dias',
  },
];
