import { ReactElement, useState } from 'react';

import { useWindowSizeStore } from '../../stores/useWindowSizeStore';
import { BuildingIcon } from '../icons/BuildingIcon';
import { IdIcon } from '../icons/IdIcon';
import { businessPlans } from './data/plans';
import { PlanCard } from './PlanCard';
import { PlanSelector } from './PlanSelector';

export function BusinessPlans(): ReactElement {
  const width = useWindowSizeStore(state => state.width);

  const [typeBusinessRange, setTypeBusinessRange] = useState<number>(1);

  const handleTypeBusinessRangeChange = (type: any) => {
    setTypeBusinessRange(type);
  };

  const isTypeRange = (type: number) => {
    return typeBusinessRange === type;
  };

  return (
    <section
      id="empresa"
      className="min-[1548px]:h-[1372px] min-[1548px]:pt-[486px] pt-20 relative"
    >
      <div className="mx-auto  min-[1548px]:w-[1500px] max-[1548px]:px-4 flex flex-col justify-center items-center">
        <div className="flex flex-col items-center gap-3">
          <h2 className="text-center min-[1548px]:text-[2.625rem] min-[701px]:text-[2rem] text-lg text-primary-main font-semibold">
            Planos para empresas
          </h2>
          <p className="text-center text-secondary-main min-[1548px]:text-2xl min-[701px]:text-xl text-base min-[1548px]:w-[920px]">
            Conheça nossos planos e convênios, escolha a opção que se encaixa
            com o perfil da sua empresa.
          </p>
        </div>

        <div className="min-[1548px]:mt-16 mb-16 flex">
          <PlanSelector
            onClick={() => handleTypeBusinessRangeChange(1)}
            text={
              width >= 701 ? 'Planos para 2 até 29 vidas' : '2 até 29 vidas'
            }
            active={isTypeRange(1)}
            Icon={IdIcon}
          />
          <PlanSelector
            onClick={() => handleTypeBusinessRangeChange(2)}
            text={
              width >= 701 ? 'Planos para 30 até 199 vidas' : '30 até 199 vidas'
            }
            active={isTypeRange(2)}
            Icon={BuildingIcon}
          />
        </div>
        <ul className="grid grid-cols-3 max-[1548px]:grid-cols-2 max-[701px]:grid-cols-1 gap-6 list-none">
          {businessPlans
            .filter(plan => plan.typeRangeLives === typeBusinessRange)
            .map(plan => (
              <PlanCard key={plan.name} plan={plan} type={'business'} />
            ))}
        </ul>
      </div>
    </section>
  );
}
