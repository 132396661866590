import { ReactElement, useEffect, useState } from 'react';

import { Button } from '../Button';
import { IdIcon } from '../icons/IdIcon';
import { Select } from '../Select';
import { DentistCategoryItem } from './DentistCategoryItem';
import { FoundDentistPlanCard } from './FoundDentistPlanCard';
import { motion } from 'framer-motion';
import { HashLink } from 'react-router-hash-link';
import shuffle from '../../utils/ArrayUtils';

interface Especialidade {
  quantidade: number;
  nomeFuncionario: string;
}

export function FindDentistsSection(): ReactElement {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [otherSpecialities, setOtherSpecialities] = useState(1);

  const [selectedState, setSelectedState] = useState<string>('6');
  const [selectedCity, setSelectedCity] = useState('');

  const [doctors, setDoctors] = useState([]);
  const [specialities, setSpecialities] = useState<any[]>([]);
  const [numDoctors, setNumDoctors] = useState(0);

  const [states, setStates] = useState([
    {
      Id: '',
      Sigla: '',
    },
  ]);

  const [cities, setCities] = useState([
    {
      Id: '',
      Sigla: '',
    },
  ]);

  const variants = {
    initial: {
      opacity: 0,
      x: 80,
    },
    animate: {
      opacity: [0, 1, 1, 0],
      x: [80, 0, 0, -80],
      transition: {
        duration: 3.5,
        repeat: Infinity,
        repeatDelay: 0.5,
        ease: 'linear',
      },
    },
    exit: {
      opacity: 0,
      x: 80,
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(
        prevIndex => (prevIndex + 1) % ((doctors && doctors.length) || 0),
      );
    }, 4000);

    return () => clearInterval(interval);
  }, [doctors]);

  useEffect(() => {
    const getStatesS4E = async () => {
      const getStates = await fetch(
        `${process.env.REACT_APP_GET_STATES}?token=${process.env.REACT_APP_TOKEN_S4E}`,
      ).then(response => response.json());

      setStates(getStates.dados);
    };
    getStatesS4E();
  }, []);

  const getDoctorsS4E = async () => {
    const getDoctors = await fetch(
      `${process.env.REACT_APP_GET_ACTIVE_DENTISTS}?token=${
        process.env.REACT_APP_TOKEN_S4E
      }&idEstado=${selectedState}&idCidade=${selectedCity}&pagina=${1}`,
    ).then(response => response.json());

    shuffle(getDoctors.dataObject.dados);

    setDoctors(getDoctors.dataObject.dados);
  };

  const getNumDoctorsS4E = async () => {
    const getNumDoctors = await fetch(
      `${process.env.REACT_APP_GET_DENTISTS_STATE_CITY}?token=${
        process.env.REACT_APP_TOKEN_S4E
      }&idEstado=${selectedState}&idCidade=${selectedCity}&pagina=${1}`,
    ).then(response => response.json());

    setNumDoctors(getNumDoctors.dataObject.dados[0].totalDentistasEstado);
  };

  const getSpecialityDoctorsS4E = async () => {
    const getSpecialities = await fetch(
      `${process.env.REACT_APP_GET_DENTISTS_SPECIALTY}?token=${
        process.env.REACT_APP_TOKEN_S4E
      }&idEstado=${selectedState}&idCidade=${selectedCity}&pagina=${1}`,
    ).then(response => response.json());

    ordenarPorQuantidade(getSpecialities.dataObject.dados);
    setSpecialities(getSpecialities.dataObject.dados);
    setOtherSpecialities(
      getSpecialities.dataObject.dados.length >= 5
        ? getSpecialities.dataObject.dados.length - 4
        : 0,
    );
  };

  const ordenarPorQuantidade = (especialidades: Especialidade[]) => {
    especialidades.sort((a, b) => b.quantidade - a.quantidade);
  };

  useEffect(() => {
    getNumDoctorsS4E();
    getSpecialityDoctorsS4E();
    getDoctorsS4E();
    getPlanValueByCEP();
  }, [selectedState]);

  //To get numbers of the selected city
  useEffect(() => {
    getNumDoctorsS4E();
    getSpecialityDoctorsS4E();
    getDoctorsS4E();
    getPlanValueByCEP();
    setCurrentIndex(0);
  }, [selectedCity]);

  const getPlanValueByCEP = async () => {
    const planValue = await fetch(
      `${process.env.REACT_APP_GET_CITIES}?token=${
        process.env.REACT_APP_TOKEN_S4E
      }&ufID=${selectedState}&idCidade=${selectedCity}&apenasRedeCredenciada=${1}`,
    ).then(response => response.json());
    setCities(planValue.dados);
  };

  const getRandomSpecialty = (specialty: string) => {
    if (specialty) {
      const listSpecialty = specialty.split(',');

      return listSpecialty[Math.floor(Math.random() * listSpecialty.length)];
    }

    return '';
  };

  const getFieldFuncionario = field => {
    if (doctors && doctors.length && doctors.length > currentIndex) {
      return doctors[currentIndex][field];
    }
    return '';
  };

  const getSpecilityByFieldAndPosition = (
    position: number,
    field: string,
  ): any => {
    if (specialities && specialities[position]) {
      return specialities[position][field];
    }
    return 0;
  };

  const getNumberSpecialities = (): any => {
    if (specialities && specialities.length) {
      return otherSpecialities;
    }
    return 0;
  };

  return (
    <section className="my-20 relative">
      <div className="min-[1092px]:h-[800px] h-[900px] absolute bg-[#F0F0F5] left-0 top-0 min-[2000px]:w-[46%] w-[42%] -z-10 rounded-3xl max-[692px]:hidden" />
      <div className="max-w-[1489px] mx-auto flex max-[692px]:flex-col max-[692px]:px-0 gap-12 max-[1565px]:px-[38px]">
        <div className="max-[692px]:py-6 max-[692px]:px-8 max-[692px]:items-center min-[1092px]:h-[800px] min-[692px]:h-[900px] flex flex-col min-[1479px]:w-[784px] justify-center min-[692px]:bg-plan-frame bg-no-repeat bg-left-bottom max-[1565px]:bg-[#F0F0F5] min-[692px]:rounded-3xl min-[692px]:pr-[38px]">
          <h2 className="text-primary-main font-semibold text-[2.625rem] max-w-[474px] relative max-[692px]:text-lg max-[692px]:font-bold max-[692px]:text-center">
            Encontre dentistas perto de você!
            <img
              src="/assets/images/sorriso.png"
              alt="Ilustração de sorriso"
              className="absolute -right-16 top-16 max-[1240px]:right-8 max-[1240px]:invisible"
            />
          </h2>
          <div className="min-[1240px]:mt-24 mt-12 flex gap-8 min-[1240px]:w-[496px] max-[1240px]:flex-col w-full">
            <Select
              label="Estado"
              placeholder="Estado"
              value={selectedState}
              onChange={e => {
                getPlanValueByCEP;
                setSelectedCity('');
                setSelectedState(e.target.value);
              }}
              options={[]}
              optionsFull={states}
            />
            <Select
              label="Cidade"
              placeholder="Cidade"
              value={selectedCity}
              onChange={e => {
                setSelectedCity(e.target.value);
              }}
              options={[]}
              optionsFull={cities}
            />
          </div>
          <div className="mt-9 flex items-center min-[1240px]:gap-14 gap-8 max-[692px]:hidden">
            <FoundDentistPlanCard />
            <div>
              <p className="min-[1240px]:w-[237px] min-[1240px]:text-2xl text-lg text-medium-emphasis mb-4">
                Planos a partir de{' '}
                <strong className="font-semibold">R$ 41,90 </strong>
                por mês
              </p>
              <HashLink to={'#individual'}>
                <Button text="Ver planos" />
              </HashLink>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center min-[1479px]:w-[940px] min-[900px]:ml-16 max-[692px]:px-6">
          <p className="text-medium-emphasis min-[771px]:text-[2.5rem] text-3xl text-center min-[1479px]:w-[700px] max-[692px]:text-lg max-[692px]:max-w-[300px]">
            {numDoctors > 1 ? (
              <span>Existem mais de </span>
            ) : (
              <span>Existe </span>
            )}
            <strong className="text-secondary-main font-semibold">
              {numDoctors}
            </strong>{' '}
            {/* <strong className="text-secondary-main font-semibold">{1250}</strong>{' '} */}
            {numDoctors > 1 ? (
              <span>dentistas perto de você</span>
            ) : (
              <span>dentista perto de você</span>
            )}
          </p>
          {specialities.length >= 1 && doctors.length >= 1 && (
            <>
              <ul className="w-full min-[1479px]:h-[111px] max-[1479px]:py-4 max-[1479px]:px-6 border border-secondary-main rounded-lg mt-16 max-[692px]:mt-6 flex items-center px-6 gap-4 max-[1479px]:flex-wrap">
                {specialities.map(
                  (element, index) =>
                    index < 4 && (
                      <DentistCategoryItem
                        key={element.idEspecialidade}
                        countNumber={getSpecilityByFieldAndPosition(
                          index,
                          'quantidade',
                        )}
                        text={getSpecilityByFieldAndPosition(
                          index,
                          'nomeEspecialidade',
                        )}
                      />
                    ),
                )}
                {otherSpecialities >= 1 && (
                  <li className="inline-block ">
                    <span className="text-lg text-medium-emphasis flex gap-4">
                      {' '}
                      <strong className="font-bold text-medium-emphasis line break-normal">
                        {'+' + getNumberSpecialities()}
                      </strong>
                      {'   '}
                      {otherSpecialities > 1 ? (
                        <span>especialidades</span>
                      ) : (
                        <span>especialidade</span>
                      )}
                    </span>
                  </li>
                )}
              </ul>
              <motion.div
                key={currentIndex}
                initial="initial"
                animate="animate"
                variants={variants}
                className="flex max-[950px]:flex-col justify-center items-center gap-4
              mt-16 max-[692px]:mt-6 min-[1479px]:w-[576px] w-full h-[103px] max-[950px]:h-[120px] shadow-card
              rounded-xl min-[950px]:border-l-[24px]
              min-[950px]:border-r-[24px]
              border-secondary-main"
              >
                <span className="font-semibold text-medium-emphasis text-center">
                  {getFieldFuncionario('nomeFuncionario')}
                </span>
                <div className="flex items-center gap-2">
                  <IdIcon />
                  <span className="text-neutral-main">
                    {getRandomSpecialty(getFieldFuncionario('especialidade'))}
                  </span>
                </div>
              </motion.div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
