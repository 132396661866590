import { ReactElement, useState } from 'react';

import clsx from 'clsx';
import { AnimatePresence, motion, Variants } from 'framer-motion';

import { CaretDownIcon } from './icons/CaretDownIcon';

type FAQProps = {
  title: string;
  answer: any;
};

export function QuestionAccordion({ title, answer }: FAQProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  function toggleActive() {
    setIsOpen(prevState => !prevState);
  }

  const variants: Variants = {
    open: {
      height: 'auto',
      opacity: 1,
      paddingTop: '1rem',
      paddingBottom: '1rem',
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      display: 'block',
    },

    closed: { height: 0, opacity: 0 },
  };

  return (
    <li className=" bg-white shadow-card rounded-2xl w-full overflow-hidden">
      <button
        className="py-4 px-6 flex flex-col w-full"
        onClick={toggleActive}
        aria-expanded={isOpen}
        aria-controls="accordion-content"
        tabIndex={0}
      >
        <div className="flex justify-between w-full">
          <h3 className="text-xl text-high-emphasis text-left">{title}</h3>
          <CaretDownIcon
            className={clsx(
              'stroke-neutral-main ',
              isOpen ? 'rotate-180' : 'rotate-0',
            )}
          />
        </div>
      </button>
      <AnimatePresence>
        <motion.p
          id="accordion-content"
          variants={variants}
          initial="closed"
          animate={isOpen ? 'open' : 'closed'}
          className="text-start text-medium-emphasis flex flex-col w-full"
        >
          {answer}
        </motion.p>
      </AnimatePresence>
    </li>
  );
}
