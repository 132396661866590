import { ReactElement, useState } from 'react';

import { cva } from 'class-variance-authority';

import { BuildingIcon } from '../../components/icons/BuildingIcon';
import DocumentIcon from '../../components/icons/DocumentIcon';
import { NewPersonalPlans } from '../../components/NewPersonalPlans';
import { businessPlans } from '../../components/NewPersonalPlans/data/plans';
import { useWindowSizeStore } from '../../stores/useWindowSizeStore';

const firstList = businessPlans.filter(
  p => p.category[0].type === '2 a 29 Vidas',
);

const secondList = businessPlans.filter(
  p => p.category[0].type === '30 a 199 Vidas',
);

const selectedDiv = cva(
  'flex text-lg border-b px-4 gap-2 text-medium-emphasis cursor-pointer border-neutral-lighter',
  {
    variants: {
      state: {
        selected: 'font-bold border-b-2 border-secondary-main',
        default: 'font-medium',
      },
    },
    defaultVariants: {
      state: 'default',
    },
  },
);

function DesktopPlanList(): ReactElement {
  const [list, setList] = useState<'first' | 'second'>('first');

  const planList = list === 'first' ? firstList : secondList;

  return (
    <>
      <div className="w-fit mx-auto flex">
        <div
          className={selectedDiv({
            state: list === 'first' ? 'selected' : 'default',
          })}
          onClick={() => setList('first')}
        >
          <DocumentIcon color={list === 'first' ? '#E6007E' : '#5C6770'} />
          <p>Planos para 2 até 29 vidas</p>
        </div>
        <div
          className={selectedDiv({
            state: list === 'second' ? 'selected' : 'default',
          })}
          onClick={() => setList('second')}
        >
          <BuildingIcon color={list === 'second' ? '#E6007E' : '#5C6770'} />
          <p>Planos para 30 até 199 vidas</p>
        </div>
      </div>
      <NewPersonalPlans
        className="!w-fit"
        customList={planList}
        type="business"
      />
    </>
  );
}

function MobilePlanList(): ReactElement {
  return (
    <>
      <div className="w-fit mx-auto flex">
        <h3 className="text-lg uppercase text-medium-emphasis font-semibold">
          Planos para 2 até 29 vidas
        </h3>
      </div>
      <NewPersonalPlans type="business" customList={firstList} />
      <div className="w-fit mx-auto flex mt-32">
        <h3 className="text-lg uppercase text-white font-semibold">
          Planos para 30 até 199 vidas
        </h3>
      </div>
      <NewPersonalPlans type="business" customList={secondList} />
    </>
  );
}

export default function ContractSection(): ReactElement {
  const { width } = useWindowSizeStore();

  return (
    <section className="max-[1300px]:mb-[-88rem]" id="company-contracts">
      {/* <section> */}
      <div className="mx-auto text-center max-w-[990px] mb-10">
        <h2 className="min-[1548px]:text-[2.625rem] min-[701px]:text-[2rem] text-lg text-primary-main font-semibold mb-3 mx-auto">
          Contrate Agora
        </h2>
        <p className="min-[1548px]:text-2xl min-[701px]:text-xl text-base text-secondary-main font-normal">
          Conheça nossos planos e convênios, escolha a opção que se encaixa com
          o perfil da sua empresa.
        </p>
      </div>
      {width >= 1300 ? <DesktopPlanList /> : <MobilePlanList />}
    </section>
  );
}
