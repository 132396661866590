import { ReactElement } from 'react';

export function NetworkMap(): ReactElement {
  return (
    <img
      src="/assets/images/network/mapa.png"
      alt="Mapa de rede de parceiros"
      className="min-[1607px]:h-[819px] min-[1607px]:w-[826px] min-[701px]:h-[500px] min-[701px]:w-[504px] bottom-0 min-[1607px]:absolute min-[1606px]:-right-10 right-6"
    />
  );
}
