import { ReactElement, ComponentProps } from 'react';

interface Props {
  className?: ComponentProps<'svg'>['className'];
  color?: string;
}

const DocumentIcon = ({
  className,
  color = '#E6007E',
}: Props): ReactElement => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 6.70312H5C4.46957 6.70313 3.96086 6.91384 3.58579 7.28891C3.21071 7.66398 3 8.17269 3 8.70312V17.7031C3 18.2336 3.21071 18.7423 3.58579 19.1173C3.96086 19.4924 4.46957 19.7031 5 19.7031H19C19.5304 19.7031 20.0391 19.4924 20.4142 19.1173C20.7893 18.7423 21 18.2336 21 17.7031V8.70312C21 8.17269 20.7893 7.66398 20.4142 7.28891C20.0391 6.91384 19.5304 6.70313 19 6.70312H14M10 6.70312V5.70312C10 5.17269 10.2107 4.66398 10.5858 4.28891C10.9609 3.91384 11.4696 3.70313 12 3.70312C12.5304 3.70313 13.0391 3.91384 13.4142 4.28891C13.7893 4.66398 14 5.17269 14 5.70312V6.70312M10 6.70312C10 7.23356 10.2107 7.74227 10.5858 8.11734C10.9609 8.49241 11.4696 8.70312 12 8.70312C12.5304 8.70312 13.0391 8.49241 13.4142 8.11734C13.7893 7.74227 14 7.23356 14 6.70312M9 14.7031C9.53043 14.7031 10.0391 14.4924 10.4142 14.1173C10.7893 13.7423 11 13.2336 11 12.7031C11 12.1727 10.7893 11.664 10.4142 11.2889C10.0391 10.9138 9.53043 10.7031 9 10.7031C8.46957 10.7031 7.96086 10.9138 7.58579 11.2889C7.21071 11.664 7 12.1727 7 12.7031C7 13.2336 7.21071 13.7423 7.58579 14.1173C7.96086 14.4924 8.46957 14.7031 9 14.7031ZM9 14.7031C10.306 14.7031 11.417 15.5381 11.83 16.7031M9 14.7031C8.37938 14.7029 7.77397 14.8952 7.26715 15.2534C6.76032 15.6115 6.37701 16.1181 6.17 16.7031M15 11.7031H18M15 15.7031H17"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentIcon;
