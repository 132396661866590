import { create } from 'zustand';

type ErrorState = {
  error: boolean;
  title: string;
  text: string;
  setError: (error: boolean) => void;
  setErrorTitle: (title: string) => void;
  setErrorText: (text: string) => void;
};

export const useError = create<ErrorState>(set => ({
  error: false,
  title: '',
  text: '',
  setError: (error: boolean) => set({ error }),
  setErrorTitle: (title: string) => set({ title }),
  setErrorText: (text: string) => set({ text }),
}));
