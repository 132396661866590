import * as z from 'zod';

import { schema } from './';

export const billetSchema = z.object({
  payment: z.optional(
    z.object({
      billetDate: z.optional(
        z.string().min(1, { message: 'Campo obrigatório' }),
      ),
    }),
  ),

  user: schema,
});

export type BilletSchema = z.infer<typeof billetSchema>;
