import { ReactElement } from 'react';

function Loading(): ReactElement {
  return (
    <div className="flex w-full h-full justify-center items-center m-auto content-center self-center bg-opacity-10 bg-black">
      <img className="w-6" src="/assets/images/spinner.svg" alt="Loading" />
    </div>
  );
}

export default Loading;
