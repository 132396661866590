import * as z from 'zod';

import { dependentsSchema } from './dependentsSchema';

export const schema = z.object({
  fullName: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  birthDate: z.optional(
    z
      .string()
      .min(1, { message: 'Campo obrigatório' })
      .regex(/^(\d{2})\/(\d{2})\/(\d{4})$/, { message: 'Data inválida' }),
  ),
  phone: z.optional(
    z
      .string()
      .min(15, { message: 'Campo obrigatório' })
      .regex(/^\(\d{2}\)\s\d{5}-\d{4}$/, { message: 'Telefone inválido' }),
  ),
  email: z.optional(
    z
      .string()
      .min(1, { message: 'Campo obrigatório' })
      .email({ message: 'E-mail inválido' }),
  ),
  token: z.optional(
    z
      .string()
      .min(6, { message: 'Campo obrigatório' })
      .regex(/^\d{1}\s.\s\d{1}\s.\s\d{1}\s.\s\d{1}\s.\s\d{1}\s.\s\d{1}$/, {
        message: 'Token inválido',
      }),
  ),
  gender: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  civilStatus: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  firstMothersName: z.optional(
    z.string().min(1, { message: 'Campo obrigatório' }),
  ),
  fullMothersName: z.optional(
    z.string().min(1, { message: 'Campo obrigatório' }),
  ),

  address: z.optional(
    z.object({
      cep: z.optional(
        z
          .string()
          .min(9, { message: 'Campo obrigatório' })
          .regex(/^[0-9]{5}-[0-9]{3}$/, { message: 'CEP inválido' }),
      ),
      uf: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
      city: z.string().min(1, { message: 'Campo obrigatório' }),
      neighborhood: z.string().min(1, { message: 'Campo obrigatório' }),
      street: z.string().min(1, { message: 'Campo obrigatório' }),
      number: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
      complement: z.optional(z.string()),
    }),
  ),

  documents: z.optional(
    z.object({
      cns: z.optional(z.string()),
      cpf: z.optional(
        z
          .string()
          .min(14, { message: 'Campo obrigatório' })
          .regex(/^(?!\d{11})\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/, {
            message: 'CPF inválido',
          }),
      ),
      rg: z.optional(z.string()),
      issuingAgency: z.optional(
        z.string().min(1, { message: 'Campo obrigatório' }),
      ),
      ufIssuingAgency: z.string().min(1, { message: 'Campo obrigatório' }),
    }),
  ),

  beneficiaryIsSameAsFinancialResponsible: z.boolean(),
  financialResponsible: z.optional(
    z.object({
      fullName: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
      email: z.optional(
        z
          .string()
          .min(1, { message: 'Campo obrigatório' })
          .email({ message: 'E-mail inválido' }),
      ),
      cpf: z.optional(
        z
          .string()
          .min(14, { message: 'Campo obrigatório' })
          .regex(/^(?!\d{11})\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/, {
            message: 'CPF inválido',
          }),
      ),
      birthDate: z.optional(
        z
          .string()
          .min(1, { message: 'Campo obrigatório' })
          .regex(/^(\d{2})\/(\d{2})\/(\d{4})$/, { message: 'Data inválida' }),
      ),
      phone: z.optional(
        z
          .string()
          .min(15, { message: 'Campo obrigatório' })
          .regex(/^\(\d{2}\)\s\d{5}-\d{4}$/, { message: 'Telefone inválido' }),
      ),
      address: z.optional(
        z.object({
          cep: z.optional(
            z
              .string()
              .min(9, { message: 'Campo obrigatório' })
              .regex(/^[0-9]{5}-[0-9]{3}$/, { message: 'CEP inválido' }),
          ),
          neighborhood: z.optional(
            z.string().min(1, { message: 'Campo obrigatório' }),
          ),
          uf: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
          city: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
          street: z.optional(
            z.string().min(1, { message: 'Campo obrigatório' }),
          ),
          number: z.optional(
            z.string().min(1, { message: 'Campo obrigatório' }),
          ),
          complement: z.optional(z.string()),
        }),
      ),
    }),
  ),

  dependents: z.optional(z.array(dependentsSchema)),

  paymentMethod: z.optional(z.string()),
});

export type Schema = z.infer<typeof schema>;
