import { ReactElement } from 'react';

import { HirePlanFormProvider } from '../../contexts/HirePlanFormProvider';
import { useHirePlanFormStepStore } from '../../stores/useHirePlanFormStepStore';
import { useWindowSizeStore } from '../../stores/useWindowSizeStore';
import { breadcrumbSteps, bussinessBreadcrumbSteps } from './data/breadcrumb';
import { HirePlanFormBreadcrumb } from './HirePlanFormBreadcrumb';
import { HirePlanFormStepHandler } from './HirePlanFormStepHandler';
import { HireBussinessPlanFormStepHandler } from './HireBussinessPlanFormStepHandler';
import { HirePlanMobileBreadcrumb } from './mobile/HirePlanMobileBreadcrumb';
import { useRouteLoaderData } from 'react-router-dom';

export function HirePlanForm(): ReactElement {
  const individualPlanData: any = useRouteLoaderData('token');
  const bussinessPlanData: any = useRouteLoaderData('token-empresa');
  let data = individualPlanData;
  if (individualPlanData == undefined) {
    data = bussinessPlanData;
  } else {
    data = individualPlanData;
  }
  const step = useHirePlanFormStepStore(state => state.step);
  const width = useWindowSizeStore(state => state.width);

  return (
    <div className="bg-white min-[748px]:pt-16 min-[748px]:px-14 pb-14 flex flex-col min-[748px]:shadow-card min-[748px]:rounded-b-xl items-center">
      {width >= 748 ? (
        <HirePlanFormBreadcrumb.Root>
          {data.type == 'individual'
            ? breadcrumbSteps.map((item, index) => (
                <HirePlanFormBreadcrumb.Item
                  key={item.name}
                  text={item.name}
                  number={index + 1}
                  active={item.step === step}
                />
              ))
            : bussinessBreadcrumbSteps.map((item, index) => (
                <HirePlanFormBreadcrumb.Item
                  key={item.name}
                  text={item.name}
                  number={index + 1}
                  active={item.step === step}
                />
              ))}
        </HirePlanFormBreadcrumb.Root>
      ) : (
        <HirePlanMobileBreadcrumb />
      )}

      <HirePlanFormProvider>
        {data.type == 'individual' ? (
          <HirePlanFormStepHandler />
        ) : (
          <>{/* <HireBussinessPlanFormStepHandler /> */}</>
        )}
      </HirePlanFormProvider>
    </div>
  );
}
