import { ReactElement, ComponentProps } from 'react';

interface Props {
  className?: ComponentProps<'svg'>['className'];
}

const ClipIcon = ({ className }: Props): ReactElement => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8264 10.1717C13.0763 9.42179 12.059 9.00053 10.9984 9.00053C9.93771 9.00053 8.92048 9.42179 8.17037 10.1717L4.17037 14.1717C3.78833 14.5407 3.4836 14.982 3.27397 15.4701C3.06433 15.9581 2.95399 16.483 2.94937 17.0141C2.94475 17.5452 3.04596 18.0719 3.24708 18.5635C3.44821 19.0551 3.74522 19.5017 4.12079 19.8773C4.49636 20.2528 4.94297 20.5498 5.43456 20.751C5.92614 20.9521 6.45286 21.0533 6.98398 21.0487C7.51509 21.0441 8.03997 20.9337 8.52799 20.7241C9.01601 20.5144 9.45738 20.2097 9.82637 19.8277L10.9284 18.7267M10.1704 13.8277C10.9205 14.5776 11.9377 14.9988 12.9984 14.9988C14.059 14.9988 15.0763 14.5776 15.8264 13.8277L19.8264 9.82768C20.555 9.07327 20.9582 8.06286 20.9491 7.01407C20.94 5.96528 20.5193 4.96203 19.7776 4.2204C19.036 3.47877 18.0328 3.05809 16.984 3.04898C15.9352 3.03987 14.9248 3.44304 14.1704 4.17168L13.0704 5.27168"
      stroke="#E6007E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ClipIcon;
