import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';

interface PageHelmetProps {
  title?: string;
  description?: string;
}

export function PageHelmet({
  title,
  description,
}: PageHelmetProps): ReactElement {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
}
